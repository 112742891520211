<template>
  <div class="content">
    <PageHeader :title="$t('usage.header')" />
    <div class="page-content container-fluid">
      <div class="row">
        <div class="col-lg-12 col-xl-12">
          <div class="card">
            <div class="card-header">
              <form class="form-inline">
                <date-range-picker
                  class="form-control"
                  :format="'YYYY-MM-DD'"
                  :options="options"
                  @change="changeDate"
                />
              </form>
            </div>
            <div class="card-body block-el p-0">
              <div class="table-responsive">
                <div
                  v-if="!usage && loaded"
                  class="text-center table-placeholder"
                >
                  <br />
                  <i class="fas fa-tachometer-alt font-size-80"></i>
                  <h5 class="card-title m-t-20">{{$t('usage.none')}}</h5><!-- Nenhum gasto encontrado -->
                </div>
                <table v-if="loaded" class="table">
                  <thead class="bg-light">
                    <tr>
                      <th>{{$t('usage.lbl-product')}}</th><!-- Produto -->
                      <th>{{$t('usage.lbl-quantity')}}</th><!-- Quantidade -->
                      <th>{{$t('usage.lbl-cost')}}</th><!-- Custo -->
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td class="font-weight-500">Total</td>
                      <td class="font-weight-500">{{ totalCount }}</td>
                      <td class="font-weight-500">
                        {{ totalPrice | currency4D }}
                      </td>
                    </tr>
                    <tr v-for="product in usage" :key="product.name">
                      <td>
                        <router-link :to="`${product.category}/usage`">
                          {{ product.category.toUpperCase() }}
                        </router-link>
                      </td>
                      <td>{{ product.count }}</td>
                      <td>{{ product.price | currency4D }}</td>
                    </tr>
                  </tbody>
                </table>
                <div v-else class="qt-block-ui" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import UsageService from '@/services/usage.service';
import PageHeader from '@/components/PageHeader.vue';
import moment from 'moment';

export default {
  name: 'Usage',
  components: {
    PageHeader,
  },
  data() {
    return {
      options: {
        timePicker: false,
        autoApply: true,
        className: 'form-control',
        startDate: moment().format('01/MM/YYYY'),
        maxSpan: {
          days: 31,
        },
        locale: {
          format: 'DD/MM/YYYY',
          daysOfWeek: ['Do', 'Seg', 'Ter', 'Qua', 'Qui', 'Sex', 'Sa'],
          monthNames: [
            'Jan',
            'Fev',
            'Mar',
            'Abr',
            'Mai',
            'Jun',
            'Jul',
            'Ago',
            'Set',
            'Otu',
            'Nov',
            'Dez',
          ],
        },
      },
      loaded: false,
      usage: [],
      totalCount: 0,
      totalPrice: 0,
      form: {
        sum: ['count', 'price'],
        date: {
          gte: moment().format('YYYY-MM-01'),
          lt: moment().format('YYYY-MM-DD'),
        },
        group_by: 'category',
      },
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
  },
  methods: {
    changeDate(date) {
      if (date) {
        console.log(date[0]);
        this.form.date.gte = moment(date[0], 'YYYY-MM-DD').format(
          'YYYY-MM-DD',
        );
        this.form.date.lt = moment(date[1], 'YYYY-MM-DD').add(1, 'days').format(
          'YYYY-MM-DD',
        );
      }
      this.fetchUsage();
    },
    fetchUsage() {
      this.loaded = false;
      this.totalPrice = 0;
      this.totalCount = 0;

      UsageService.getUsage(this.form).then((usage) => {
        Object.values(usage).forEach((product) => {
          this.totalPrice += product.price;
          this.totalCount += product.count;
        });
        this.usage = usage;
        this.loaded = true;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.table-responsive {
  min-height: 150px;
}
</style>
